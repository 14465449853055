<template>
  <div id="GedDocumentos">
    <!-- <v-card elevation="0" class="pa-0" style="border-radius: 0;">
      <v-card-title :style="{ 'background-color':COR_PRINCIPAL }"
                    class="justify-space-between py-1 px-0">
        <div>
          <div>
            <span class="title-page pl-2">Cobrança nº {{dados_documentos.cod_empreendcobranca}}</span>
            <span class="title-page-menor ml-2 mr-2"> - </span>
            <span class="title-page-menor">{{dados_documentos.empreendcobr_descricao}} ( {{dados_documentos.empreendcobr_parcela_inicial}} / {{dados_documentos.empreendcobr_parcela_final}} )</span>
            <span v-if="dados_documentos.empreendcobr_situacao == 'Pago'" class="status_ativo ml-3">
              {{ dados_documentos.empreendcobr_situacao }}
            </span>
            <span v-if="dados_documentos.empreendcobr_situacao == 'Aberto'" class="status_pendente ml-3">
              {{ dados_documentos.empreendcobr_situacao }}
            </span>
            <span v-if="dados_documentos.empreendcobr_situacao == 'Rescindido'" class="status_cancelado ml-3">
              {{ dados_documentos.empreendcobr_situacao }}
            </span>
            <span v-if="dados_documentos.empreendcobr_situacao == 'Inativo'" class="status_cancelado ml-3">
              {{ dados_documentos.empreendcobr_situacao }}
            </span>
            </div>
        </div>
        <v-btn @click="fechar()" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="wrapper pa-0 pagar-form" >
        <v-divider dark></v-divider>

        <div v-bind:style="{ 'background-color':COR_PRINCIPAL }">
          <v-row v-if="dados_documentos.tipo == 'CONTAS A PAGAR'" class="ml-1 mt-0">
            <v-col>
              <label
                style="color:silver;font-size:14px;font-style: bold;">Favorecido:
              </label>
              <label
                style="color:white;font-size:15px;"
                class="mr-4">
                  {{ dados_documentos.cobr_pess ? dados_documentos.cobr_pess?.pessoa_nome : dados_documentos.pessoa_nome }} 
              </label>
              <label style="color:white;font-size:15px;" class="mr-4">|</label>
              <label
                style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
              </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{ dados_documentos.cobr_pess ? dados_documentos.emp_cobr?.empreend_nome : dados_documentos.empreend_nome}}
              </label>
            </v-col>
          </v-row>
          <v-row v-if="dados_documentos.tipo == 'CONTAS A RECEBER'" class="ml-1 mt-0">
            <v-col>
              <label
                style="color:silver;font-size:14px;font-style: bold;">Devedor:
              </label>
              <label
                style="color:white;font-size:15px;"
                class="mr-4">
                  {{ dados_documentos.empreend_venda.pessoa_venda.pessoa_nome }} 
              </label>
              <label style="color:white;font-size:15px;" class="mr-4">|</label>
              <label
                style="color:silver;font-size:14px;font-style: bold;">Empreendimento:
              </label>
                <label
                style="color:white;font-size:15px;"
                class="mr-5">
                {{ dados_documentos.empreend_venda.vendas.empreend_nome}}
              </label>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card> -->

    <v-toolbar-title
      class="headline lighten-2 d-flex justify-space-between pt-5 px-2"
    >
      <!-- TÍTULO DA PÁGINA -->
      <v-toolbar-title
        :style="{ color: COR_SUBTITULO }"
        class="body-1 font-primary ml-2 mt-1"
      >
        <p
          class="font-primary font-weight-bold font-weight-medium body-1"
          :style="{ color: COR_SUBTITULO }"
        >
          Documentos
        </p>
      </v-toolbar-title>
      <!-- FIM TÍTULO DA PÁGINA -->

      <!-- BOTÕES DE AÇÃO CABECALHO -->
      <v-toolbar-title
        class="container_btn-header d-flex flex-column mt-0 ml-4 pa-0"
        dark
        elevation="0"
      >
        <div class="d-flex" style="align-items: baseline">
          <div class="text-center d-flex mr-4" v-if="tamanhoArray > 0">
            <v-btn
              elevation="0"
              fab
              height="35"
              width="35"
              class="mr-2"
              @click="all"
            >
              <v-icon :color="COR_SUBTITULO">
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <v-btn elevation="0" fab height="35" width="35" @click="none">
              <v-icon :color="COR_SUBTITULO">
                mdi-arrow-collapse-vertical
              </v-icon>
            </v-btn>
          </div>

          <v-btn
            @click="abre_Novo()"
            class="caption font-weight-medium mr-4"
            v-bind:style="{ color: COR_SUBTITULO }"
            :small="isMobile"
            elevation="0"
          >
            <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
            <span>Novo</span>
          </v-btn>
        </div>
      </v-toolbar-title>
      <!-- FIM BOTÕES DE AÇÃO CABECALHO -->
    </v-toolbar-title>

    <!-- Accordion com os documentos -->
    <v-row dense class="px-0 pl-1 pt-4">
      <v-expansion-panels v-model="panel" multiple class="px-4">
        <v-expansion-panel v-for="(documentos, i) in this.dados" :key="i">
          <v-expansion-panel-header
            v-if="!!documentos && documentos.length > 0"
          >
            {{ documentos[0].tipo_documento }}
            <v-badge
              :content="documentos.length"
              color="blue accent-1"
              offset-x="30"
              offset-y="10"
            >
            </v-badge>
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="px-0 pl-1">
              <v-col
                v-for="(item, ii) in documentos"
                :key="ii"
                class="col-wrapper mr-3 mb-3 pa-0 mt-3"
              >
                <!--------- ÍCONES DE AÇÃO ------------------------------->
                <div v-if="item">
                  <!-- <pre>{{item}}</pre> -->
                  <div class="container_btn-icon rounded">
                    <v-menu offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          dark
                          class="btn-icon mr-1 mb-4"
                          color="grey"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon v-if="item.situacao != 'Aprovado'"
                            >mdi-dots-vertical</v-icon
                          >
                          <v-icon v-else color="green">mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <v-list v-if="item.situacao != 'Aprovado'">
                        <v-list-item
                          @click="() => {}"
                          v-if="item.situacao === 'Aguardando Aprovação'"
                        >
                          <v-list-item-title
                            @click="dialogoAprovar(item, 'Aprovado')"
                          >
                            <v-icon color="green" class="mr-2 icon-menu"
                              >mdi-check
                            </v-icon>
                            Aprovar
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="() => {}"
                          v-if="item.situacao === 'Aguardando Aprovação'"
                        >
                          <v-list-item-title
                            @click="dialogoAprovar(item, 'Reprovado')"
                          >
                            <v-icon color="red" class="mr-2 icon-menu"
                              >mdi-close</v-icon
                            >
                            Reprovar
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="() => {}"
                          v-if="item.situacao === 'Reprovado'"
                        >
                          <v-list-item-title>
                            <div @click="dialogoAprovar(item, 'Motivo')">
                              <v-icon color="#84A0CD" class="mr-2 icon-menu"
                                >mdi-eye</v-icon
                              >
                              Motivo
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div
                      v-if="
                        item?.tipo_arquivo?.toLowerCase() === 'jpg' ||
                        item?.tipo_arquivo?.toLowerCase() === 'png' ||
                        item?.tipo_arquivo?.toLowerCase() === 'bmp' ||
                        item?.tipo_arquivo?.toLowerCase() === 'pdf'
                      "
                    >
                      <v-btn
                        icon
                        dark
                        class="btn-icon mr-1 mb-4"
                        color="grey"
                        @click="
                          verImagem(item.imagem_caminho, item.tipo_arquivo)
                        "
                      >
                        <v-icon color="#84A0CD" class="icon-action">
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn
                        icon
                        dark
                        class="btn-icon mr-1 mb-4"
                        color="grey"
                        @click="forceFileDownload(item.imagem_caminho)"
                      >
                        <v-icon color="#84A0CD" class="icon-action">
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-btn
                      icon
                      dark
                      class="btn-icon"
                      color="grey"
                      @click="
                        cod_pessoa_documento_Aux = item.cod_ged;
                        cadastro_dt_Aux = item.cadastro_dt;
                        dialog_excluir = true;
                        tipo_Aux = item.tipo_documento;
                      "
                      :loading="loading_excluir"
                    >
                      <v-icon color="#f44336e6" class="icon-action">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <!--------- FIM ÍCONES DE AÇÃO ------------------------------->

                  <v-card
                    elevation="0"
                    class="card tabela pt-3"
                    max-width="258"
                  >
                    <v-card-text class="justify-space-between py-0 pb-1">
                      <span style="font-size: 0.8rem" class="mt-n2"
                        >{{ item.titulo }}
                      </span>
                      <span v-if="item.publico === 'SIM'">
                        -
                        <v-icon color="yellow"> mdi-star </v-icon>
                      </span>
                    </v-card-text>
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <div
                      v-if="item.existe_miniatura"
                      style="min-width: 150px; min-height: 250px"
                      class="d-flex align-center justify-center"
                    >
                      <v-img
                        :src="item.imagem_caminho_thumb"
                        class="ma-3"
                        max-height="100%"
                      ></v-img>
                    </div>
                    <div
                      v-else
                      style="min-width: 150px; min-height: 250px"
                      class="d-flex align-center justify-center"
                    >
                      <v-img
                        :src="item.imagem_caminho_thumb"
                        class="ma-3"
                        max-height="100%"
                      ></v-img>
                    </div>
                    <v-card-text class="justify-space-between py-0 pb-1">
                      <div class="caption">
                        {{
                          moment(item.cadastro_dt).format("DD/MM/YYYY") || ""
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- fim do Accordion -->
    </v-row>

    <!--------- DIALOG NOVO DOCUMENTO ------------------------------->
    <v-container v-if="dialog_GedNovoDocumentoModal">
      <v-dialog
        v-model="dialog_GedNovoDocumentoModal"
        persistent
        max-width="440px"
        style="overflow: hidden"
      >
        <GedNovoDocumentoModal
          :paramsTipoDocumento="paramsTipoDocumento"
          :cod_empreendimento_aux="cod_empreendimento_aux"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :cod_empreendvenda_aux="cod_empreendvenda_aux"
          :cod_pessoa_aux="cod_pessoa_aux"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_GedNovoDocumentoModal = false"
          @buscaDocumentos="busca(paramBusca)"
        />
      </v-dialog>
    </v-container>
    <!--------- FIM DIALOG NOVO DOCUMENTO ------------------------------->

    <!--------- DIALOG EXCLUIR DOCUMENTO ------------------------------->
    <v-container v-if="dialog_excluir">
      <v-dialog
        v-model="dialog_excluir"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0"
      >
        <v-divider></v-divider>

        <v-card elevation="0" class="">
          <v-card-title
            v-bind:style="{ 'background-color': COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3"
          >
            <span class="white--text title-page body-1">Excluir documento</span>
            <v-btn @click="dialog_excluir = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR este documento?
          </v-card-title>
          <v-card-text>
            <!-- <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br> -->
            Tipo: {{ tipo_Aux }}<br />
            Data cadastro: {{ formatDate(cadastro_dt_Aux) }}<br />
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialog_excluir = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading_excluir"
              @click="excluir()"
            >
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOG EXCLUIR DOCUMENTO  -->

    <!--------- DIALOG VER IMAGEM DOCUMENTO ------------------------------->
    <v-container>
      <v-dialog
        v-model="dialog_ver_imagem_documento"
        transition="dialog-bottom-transition"
        max-width="90%"
        max-height="90%"
        class="pa-0"
      >
        <!-- <v-divider></v-divider> -->

        <!-- <v-card elevation="0" class="">
            <v-card-actions  style="position: fixed; bottom: auto; z-index: 2;">
              <v-spacer></v-spacer>
             
  
              <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir" @click="dialog_ver_imagem_documento = false;">
                Fechar
              </v-btn>
            </v-card-actions> -->
        <v-card
          :style="{ 'background-color': COR_SECUNDARIA }"
          class="container pa-0"
        >
          <v-card-title
            :style="{ 'background-color': COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3"
          >
            <div>
              <span class="text-white title-page body-1 mr-3">Documento</span>
              <!-- <v-btn id="baixar_arquivo" download> -->
              <v-icon
                v-if="tipo_arquivo_aux !== 'pdf'"
                color="white"
                @click="forceFileDownload(modal_imagem_caminho)"
                >mdi-download</v-icon
              >
              <!-- </v-btn> -->
            </div>
            <v-btn
              @click="dialog_ver_imagem_documento = false"
              icon
              dark
              color="#F2F6F7"
            >
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <div
            v-if="
              tipo_arquivo_aux === 'jpg' ||
              tipo_arquivo_aux === 'png' ||
              tipo_arquivo_aux === 'bmp'
            "
          >
            <v-img :src="modal_imagem_caminho"></v-img>
          </div>
          <div v-else-if="tipo_arquivo_aux === 'pdf'">
            <iframe
              class="altura"
              :src="modal_imagem_caminho"
              frameborder="0"
              style="overflow: hidden; height: 80vh; width: 100%"
            ></iframe>
          </div>
          <!--------- FIM DIALOG VER IMAGEM DOCUMENTO ------------------------------->
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Ged from "./store_Ged";
import store_site from "../../store/store_site";
import GedNovoDocumentoModal from "./GedNovoDocumentoModal.vue";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
} from "../../services/constantes";
import {
  formatDate,
  resizeImage,
  blobToBase64,
  groupArrayMultKeys,
  generatePDFThumbnails,
} from "../../services/funcoes";
import { baseURL, API } from "../../services/API";
import moment from "moment";

export default {
  name: "GedDocumentos",

  props: [
    "cod_empreendcobranca_aux",
    "cod_empreendimento_aux",
    "cod_empreendvenda_aux",
    "cod_pessoa_aux",
    "dados_documentos",
    "paramsTipoDocumento",
    "paramBusca",
    "statusDocumento",
  ],

  components: {
    GedNovoDocumentoModal,
  },

  data() {
    return {
      store_Ged: store_Ged,
      store_site: store_site,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,
      formatDate: formatDate,

      filtro: null,
      panel: [],
      tamanhoArray: [],
      // search             : null,
      // search_input_focus : false,
      arrayTipoDocumento: null,
      vencimento_visivel: null,
      image: null,
      loading_salvar: false,
      loading_excluir: false,
      resizedImg: null,
      tipo_documento_aux: null,
      tipo_Aux: null,
      cadastro_dt_Aux: null,
      dialog_excluir: false,
      dialog_ver_imagem_documento: null,
      dialog_GedNovoDocumentoModal: false,
      tipo_arquivo_aux: null,
      // dados_tipo: {
      //   tipo: "",
      //   titulo: null,
      //   publico: 'NÃO',
      //   documento: null,
      // },

      // array_sim_nao: [
      //   "SIM", "NÃO",
      // ],

      /* Formulário */
      valid: false,

      dialogNovoDocumento: false,

      dados: {
        tipo: "",
        documento: null,
      },

      // rulesDocumentos: [
      //   (value) => !!value || "O documento é obrigatório",
      // ],

      // rulesTipoDocumento: [
      //   (value) => !!value || "O tipo de documento é obrigatório",
      // ],

      // rulesVencimentoDocumento: [
      //   (value) => !!value || "A data do vencimento é obrigatório",
      // ],
    };
  },

  mounted() {
    this.busca(this.paramBusca);
  },

  methods: {
    abre_Novo() {
      this.dialog_GedNovoDocumentoModal = true;
      this.resizedImg = null;
      this.dados_tipo = {
        tipo: null,
        titulo: null,
        publico: "NÃO",
        documento: null,
      };
      this.image = null;
      this.validadeAux = null;
      this.loading_salvar = false;
    },

    fechar() {
      this.$emit("fecharModal", false);
    },

    all() {
      this.panel = Array.from(Array(this.tamanhoArray).keys());
    },

    none() {
      this.panel = [];
    },

    async excluir() {
      // console.log(this.dados);
      // return
      this.loading_excluir = true;
      // console.log("this.cod_pessoa_documento_Aux", this.cod_pessoa_documento_Aux)
      const ls_Resp = await this.store_Ged.deleteGed({
        cod_ged: this.cod_pessoa_documento_Aux,
      });

      if (ls_Resp.message == "success") {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = ls_Resp.result;
        this.store_site.alert = true;

        this.busca(this.paramBusca);
      } else {
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = ls_Resp.errors;
        this.store_site.alert = true;
      }
      this.loading_excluir = false;
      this.dialog_excluir = false;
    },
    async forceFileDownload(fileName) {
      try {
        let nome_arquivo = fileName.split("/");

        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = nome_arquivo[nome_arquivo.length - 1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },

    verImagem(imagem_caminho, tipo_arquivo_aux) {
      this.dialog_ver_imagem_documento = true;
      this.modal_imagem_caminho = imagem_caminho;
      this.tipo_arquivo_aux = tipo_arquivo_aux?.toLowerCase();
    },

    async busca(val) {
      this.dados = await this.store_Ged.getGed(val);
      this.tamanhoArray = this.dados?.length || 0;

      if (Array.isArray(this.dados)) {
        this.dados.map((value) => {
          value.imagem_caminho_thumb = baseURL + value.thumb_caminho;
          value.imagem_caminho =
            baseURL +
            "imagens/ged/empresa_" +
            value.cod_empresa +
            "/" +
            value.tipo_cadastro +
            "/" +
            value.imagem_caminho;
        });
        this.dados = groupArrayMultKeys(this.dados, ["tipo_documento"]);
      }
    },

    async gedTipoDoc(p_params) {
      const { data } = await API.get(`ged_tipo_documento`, {
        params: { ...p_params },
      });

      this.arrayTipoDocumento =
        data.result === "Não possui dados" ? [] : data.result;
    },

    readURL(file) {
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = async (e) => {
        this.originalImg = e.target.result;
        //console.log("originalImg:::::: ", this.originalImg)

        if (file.type.match(/pdf.*/)) {
          const array_thumbs = await generatePDFThumbnails(
            this.originalImg,
            150,
            1
          );
          if (array_thumbs) {
            this.resizedImg = array_thumbs[0].thumbnail;
            //console.log("this.resizedImg 1::::", this.resizedImg)
          }
        } else if (file.type.match(/image.*/)) {
          // START: preview resized
          resizeImage({
            file: file,
            maxSize: 170,
          })
            .then(async (resizedImage) => {
              this.resizedImg = await blobToBase64(resizedImage);

              //this.resizedImg = URL.createObjectURL(resizedImage);
              //console.log("this.resizedImg 2::::", this.resizedImg)
            })
            .catch((err) => {
              console.error(err);
            });
          // END: preview resized
        }
      };
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original
    },

    url() {
      if (!this.image) return;

      if (imagem.type) return URL.createObjectURL(this.image);
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#GedDocumentos {
  min-height: 300px;
  /* width: 100%; */
  /* padding: 10px; */
  /* padding-top: 0; */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--COR_SECUNDARIA);
}

/* ---------- BARRA DE SCROLL ---------- */
#GedDocumentos::-webkit-scrollbar {
  width: 5px;
}

#GedDocumentos::-webkit-scrollbar-button {
  padding: 1px;
}

#GedDocumentos::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#GedDocumentos::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 100px;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px;
  font-size: 18px;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}

.search-input,
.search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px !important;
  min-width: 400px !important;
}

.container {
  max-width: initial;
}

@media (max-width: 1024px) {
  .search-input,
  .search-input-longo {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }
  .container_btn-header {
    margin-left: 0px !important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
    /* max-width: calc(100% - 15px)!important; */
  }
}

@media (max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
  }

  .container_btn-header .caption {
    margin-top: 16px !important;
  }
}

.select-order {
  max-width: 210px;
}

@media (max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px !important;
  }

  .select-order {
    margin-top: 12px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px !important;
  }
}

.col-wrapper {
  max-width: 196px;
}

@media (max-width: 1024px) {
  .col-wrapper {
    max-width: 30% !important;
  }
}

@media (max-width: 768px) {
  .col-wrapper {
    max-width: 45% !important;
  }
}

@media (max-width: 599px) {
  .col-wrapper {
    max-width: 46% !important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px !important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  height: 250px;
}

@media (max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media (max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  }
}

@media (max-width: 599px) {
  .document-name {
    max-width: 8ch;
  }
}

@media (max-width: 375px) {
  .document-name {
    max-width: 6ch;
  }
}

@media (max-width: 360px) {
  .document-name {
    max-width: 5ch;
  }
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2);
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible !important;
  opacity: 1 !important;
}

.v-dialog {
  overflow: hidden;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  font-size: 19px !important;
  color: #ffffff;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  color: #ffffff;
  letter-spacing: 0px;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media (max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}

.v-divider {
  background: var(--COR_PRINCIPAL) !important;
}
.btn-icon:hover {
  background: #fff;
}

.icon-action {
  font-size: 30px !important;
}

@media (max-width: 599px) {
  .icon-action {
    font-size: 20px !important;
  }
}

.v-dialog > * {
  overflow: hidden;
}

.divisor {
  height: 1px;
  border-color: #c0c0c0;
}
</style>
